/**
 * zero dependency loader script. Waits until the instant audit button is clicked before loading the audit script
 *
 * - determines base url to load the script from by checking the script tags on the page
 * - waits for instant audit config to be loaded to initialize.
 * - loads the script immediately if needed, or attaches event listeners if not
 *
 */
(function() {
  var SCRIPT_NAME = "instant-audit-bundle.js";
  var LOADER_MATCH = /\/instant-audit\.js(\?.*)?$/;
  var DEFAULT_BASE_URL = "https://audits.chatmeter.com";
  window._IA_QUEUE = window._IA_QUEUE || [];

  function getScriptUrl() {
    var scripts = document.getElementsByTagName("script");
    // find the script tag that loaded this javascript file in order to use a different base url than the default
    var baseUrl = DEFAULT_BASE_URL;
    for (var i = 0; i < scripts.length; i++) {
      var script = scripts[i].src;
      var matched = script.match(LOADER_MATCH);
      if (matched) {
        baseUrl = script.slice(0, matched.index);
        break;
      }
    }
    return baseUrl + "/" + SCRIPT_NAME;
  }

  var didLoad = false;
  /**
   * fetch and attaches the full instant audit bundle
   */
  function loadInstantAuditScript() {
    if (!didLoad) {
      didLoad = true;
      var script = document.createElement("script");
      script.type = "application/javascript";
      script.async = true;
      script.src = getScriptUrl();
      document.body.appendChild(script);
    }
  }

  var stylesheetInstalled = false;
  function installSpinner(btn) {
    if (!stylesheetInstalled) {
      stylesheetInstalled = true;
      addStyles(
        ".ia-loader-loadingspinner {" +
          "  margin-left: 3px;" +
          "  display: inline-block;" +
          "  pointer-events: none;" +
          "  width: 7px;" +
          "  height: 7px;" +
          "  border: 2px solid transparent;" +
          "  border-color: #999;" +
          "  border-top-color: #eee;" +
          "  border-radius: 50%;" +
          "  opacity: 10%;" +
          "  animation: ia-loader-loadingspin 600ms linear infinite;" +
          "}" +
          "" +
          "@keyframes ia-loader-loadingspin {" +
          "  100% {" +
          "      transform: rotate(360deg)" +
          "  }" +
          "}"
      );
    }
    if (!btn.querySelector(".ia-loader-loadingspinner")) {
      var spinner = document.createElement("span");
      spinner.className = "ia-loader-loadingspinner";
      spinner.style["display"] = "none";
      btn.appendChild(spinner);
      setTimeout(function() {
        spinner.style.display = "inline-block";
      }, 500);
    }
  }

  /* Function to add style element */

  function addStyles(styles) {
    /* Create style document */

    var css = document.createElement("style");
    css.type = "text/css";

    if (css.styleSheet) css.styleSheet.cssText = styles;
    else css.appendChild(document.createTextNode(styles));

    /* Append style to the tag name */

    document.getElementsByTagName("head")[0].appendChild(css);
  }

  try {
    var cfg = window.INSTANT_AUDIT_CONFIG;
    function init() {
      cfg = cfg || {};
      if (!cfg.buttonSelector) {
        loadInstantAuditScript();
      } else {
        setTimeout(loadInstantAuditScript, 30000); // load it at least after 30s
        var buttons = document.querySelectorAll(cfg.buttonSelector);
        if (!buttons.length) {
          loadInstantAuditScript();
        } else {
          for (var i = 0; i < buttons.length; i++) {
            var btn = buttons[i];
            btn.addEventListener("click", function onClick() {
              window._IA_QUEUE.push({ name: "BUTTON_CLICK", button: btn });
              loadInstantAuditScript();
              installSpinner(btn);
              for (var i = 0; i < buttons.length; i++) {
                buttons[i].removeEventListener("click", onClick);
              }
            });
          }
        }
      }
    }
    if (cfg) {
      init();
    } else {
      window.addEventListener("load", function() {
        cfg = window.INSTANT_AUDIT_CONFIG;
        init();
      });
    }
  } catch (err) {
    console.error("instant audit loader failure", err);
    loadInstantAuditScript();
  }
})();
